import request from '@/utils/request'

// 重消奖查询  经销商
export function pageBdMemCX(query) {
    return request({
        url: '/bdMem/pageBdMemCX',
        method: 'get',
        params: query
    })
}

// 重消奖导出  经销商
export function exportBdMemCX(query) {
    return request({
        url: '/bdMem/exportBdMemCX',
        method: 'get',
        params: query
    })
}

// 重消奖查看  经销商
export function viewBdMemCX(query) {
    return request({
        url: '/bdMem/viewBdMemCX',
        method: 'get',
        params: query
    })
}

// 重消奖明细查看订单  经销商
export function viewVBdMemCXOrder(query) {
    return request({
        url: '/bdMem/viewVBdMemCXOrder',
        method: 'get',
        params: query
    })
}
