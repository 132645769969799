<!-- 重消奖明细 -->
<template>
  <div class="repeatcview">
    <div v-if="zero === 1">
      <p style="text-align: left;font-size: 30px">{{$t('bonus.bonusConsum.detail')}}</p>
      <el-form style="text-align: left" label-width="auto" :inline="true" :model="listQuery" size="small" label-position="left">
        <!-- 月份 -->
        <el-form-item :label="$t('date.month')">
          <el-select v-model="listQuery.wMonth" :placeholder="$t('mi.input.select')">
            <el-option value="" />
            <el-option v-for="item in this.mothList" :key="item.wMonth" :label="$t(item.wMonth)" :value="item.wMonth" />
          </el-select>
        </el-form-item>
        <el-button size="small" :loading="btnLoading" type="warning" @click="handleFilter" plain>{{$t('btn.implement')}}</el-button>
      </el-form>
      <el-table
          :key="tableKey"
          v-loading="listLoading"
          :data="list"
          border
          fit
          highlight-current-row
          style="width: 100%;"
      >
        <!-- 操作 -->
        <el-table-column :label="$t('sysOperationLog.moduleName')" align="center">
          <template slot-scope="{row}">
            <!--          查看-->
            <el-button
                :title="$t('button.view')"
                type="primary"
                icon="el-icon-search"
                circle
                size="small"
                @click="viewBtn(row)"/>
          </template>
        </el-table-column>
        <!--    月份  -->
        <el-table-column :label="$t('date.month')" align="center" prop="wMonth" />
        <!--   重消活跃状态  -->
        <el-table-column :label="$t('RepeatC.active.status')" align="center">
          <template v-slot="{row}">
            {{ $lt(initDict.RepeatActiveState, row.activeStatus) }}
          </template>
        </el-table-column>
        <!--    经销商编号  -->
        <el-table-column :label="$t('miMember.memberNo')" align="center" prop="memberNo" />
        <!--    姓名  -->
        <el-table-column :label="$t('sysUser.userName')" align="center" prop="memberName" />
        <!--    分公司  -->
        <el-table-column :label="$t('sys.company.code')" align="center" prop="companyCode" />
        <!--    重消奖  -->
        <el-table-column :label="$t('bonus.bonusConsum')" align="center" prop="bonusConsum" />
      </el-table>
      <!--    分页-->
      <pagination
          v-show="total>0"
          :total="total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.limit"
          @pagination="getList"
      />
    </div>
    <div v-if="zero === 2">
      <p style="
          text-align: left;
          font-size: 30px;
          margin-top: 0;
          margin-bottom: 10px;
        ">{{$t('function.menu.miMemberDetail')}}</p>
      <el-button size="small" type="warning" @click="btnReturn" plain>{{$t('operation.button.return')}}</el-button>
      <el-table
          :data="viewList"
          border
          fit
          highlight-current-row
          style="width: 100%;"
      >
        <!-- 操作 -->
        <el-table-column :label="$t('sysOperationLog.moduleName')" align="center" width="70px">
          <template slot-scope="{row}">
            <!--          查看-->
            <el-button
                :title="$t('button.view')"
                type="primary"
                icon="el-icon-search"
                circle
                :loading="btnLoading"
                size="small"
                @click="viewTwoBtn(row)"
            />
          </template>
        </el-table-column>
        <!--    月份  -->
        <el-table-column :label="$t('date.month')" align="center" prop="wMonth" />
        <!--    获奖人编号  -->
        <el-table-column :label="$t('Awardee.number')" align="center" prop="memberNo" />
        <!--    贡献人编号  -->
        <el-table-column :label="$t('Contributors.number')" align="center" prop="recommendNo" />
        <!--    奖金  -->
        <el-table-column :label="$t('bd.bonusName')" align="center" prop="bonusAmt" />
        <!--    贡献人层数  -->
        <el-table-column :label="$t('common.count.layer')" align="center" prop="bonusLevel" />
        <!--    贡献人当月累计重销PV  -->
        <el-table-column :label="$t('Contributors.month.pv')" align="center" prop="bonusRadix" />
      </el-table>
    </div>
    <div v-if="zero === 3">
      <p style="text-align: left;font-size: 30px;margin-top: 0;margin-bottom: 10px;">{{$t(this.pThree)}}</p>
      <el-button style="margin-top: 1rem" type="warning" size="mini" plain @click="previousBtn">{{ $t('button.previous') }}</el-button>
      <el-table
          :data="viewTwoList"
          border
          fit
          highlight-current-row
          style="width: 100%;"
      >
        <!--      期次-->
        <el-table-column :label="$t('PoOrder.periodWeek')" prop="periodWeek" align="center" />
        <!--     分公司 -->
        <el-table-column :label="$t('rptSaledaily.companycode')" prop="companyCode" align="center" />
        <!--      订单编号-->
        <el-table-column :label="$t('poOrder.orderNo')" prop="orderNo" align="center" />
        <!--     单据类型 -->
        <el-table-column :label="$t('fiAcInput.orderType')" align="center">
          <template v-slot="{row}">
            {{ $lt(initDict.orderType, row.orderType) }}
          </template>
        </el-table-column>
        <!--    经销商编号  -->
        <el-table-column :label="$t('miMember.memberNo')" prop="memberNo" align="center" />
        <!--     经销商姓名 -->
        <el-table-column :label="$t('miMember.name')" prop="memberName" align="center" />
        <!--      代办处编号-->
        <el-table-column :label="$t('miAgent.agentNo')" prop="agentNo" align="center" />
        <!--      F$-->
        <el-table-column :label="$t('BiGoods.standardPrice')" prop="totalAmount" align="center" />
        <!--      FV-->
        <el-table-column :label="$t('Common.PV')" prop="totalFv" align="center" />
        <!--     支付积分 -->
        <el-table-column :label="$t('Points.de.paiement')" prop="totalH0" align="center" />
        <!--     支付方式 -->
        <el-table-column :label="$t('poOrder.payWay')" prop="paymentMethod" align="center">
          <template v-slot="{row}">
            {{ $lt(initDict.paymentMethod, row.paymentMethod) }}
          </template>
        </el-table-column>
        <!--      生成方式-->
        <el-table-column :label="$t('po.generateMethod')" prop="" align="center">
          <template v-slot="{row}">
            {{ $lt(initDict.generateMethod, row.generateMethod) }}
          </template>
        </el-table-column>
        <!--      订单状态-->
        <el-table-column :label="$t('pocounterorder.csstatus')" prop="statusStr" align="center" />
        <!--      结算-->
        <el-table-column :label="$t('button.settle')" prop="" align="center">
          <template v-slot="{row}">
            {{ $lt(initDict.locked, row.locked) }}
          </template>
        </el-table-column>
        <!--     原订单编号 -->
        <el-table-column :label="$t('poReturn.orderId')" prop="cancelOrderNo" align="center" />
        <!--     备注 -->
        <el-table-column :label="$t('Common.memo')" prop="memo" align="center" />
        <!--     审核时间 -->
        <el-table-column :label="$t('Common.checkTime')" prop="checkAdjustTime" align="center" />
        <el-table-column :label="$t('po.settleTime')" prop="checkTime" align="center" />
      </el-table>
    </div>
  </div>
</template>

<script>
  import { pageBdMemCX, viewBdMemCX, viewVBdMemCXOrder } from '@/api/bd/bdMem'
  import { getWmoth } from '@/api/home'
  import Pagination from "@/components/Pagination/index"
  export default {
    name: "RepeatCView",
    components: { Pagination },
    data() {
      return {
        pThree: '',
        btnLoading: false,
        viewList: [],
        viewTwoList: [],
        zero: 1,
        mothList: [],
        listQuery: {
          page: 1,
          limit: 20
        },
        list: [],
        tableKey: 0,
        listLoading: false,
        total: 0,
        initDict:{
          RepeatActiveState:'Repeat.active.state',
          orderType:'po.allordertype.cn',
          paymentMethod:'poorder.paymentmethod',
          generateMethod:'poorder.generatemethod',
          locked:'pdmanifestsettle.receiptstatus'
        }
      }
    },
    created() {
      this.getList()
      this.getMothList()
    },
    methods: {
      btnReturn() {
        this.zero = 1
        this.viewList = []
        this.getList()
      },
      viewBtn(row) {
        viewBdMemCX({ 'wMonth': row.wMonth }).then(res => {
          this.viewList = res.data
          this.zero = 2
        })
      },
      // 弹窗内表格详情
      viewTwoBtn(row) {
        viewVBdMemCXOrder({ 'recommendNo': row.recommendNo, 'wMonth': row.wMonth }).then(res => {
          this.pThree = row.recommendNo + ' - ' + this.$t('function.menu.miMemberDetail') // 明细
          this.viewTwoList = res.data
          this.zero = 3
        }).catch(() => { })
      },
      previousBtn() {
        this.zero = 2
        this.viewTwoList = []
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      getList() {
        this.btnLoading = true
        this.listLoading = true
        pageBdMemCX({ ...this.listQuery }).then(res => {
          this.list = res.data.records
          this.total = +res.data.total
          this.btnLoading = false
          this.listLoading = false
        }).catch(() => {
          this.btnLoading = false
          this.listLoading = false
        })
      },
      // 获取月份
      getMothList() {
        getWmoth().then(res => {
          this.mothList = res.data
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .table-css {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    tr {
      th {
        padding: 5px;
        width: 150px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        border: 1px solid #eeeeee;
        text-align: left;
        background: #dddddd;
      }
      td {
        padding: 5px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        border: 1px solid #dddddd;
        text-align: left;
        background: #ffffff;
        span {
          font-weight: 800;
        }
      }
    }
  }

</style>
